import React from 'react'
import Plot from 'react-plotly.js'
import {CholesterolMonitoring} from '../core/_models'

interface CholesterolChartProps {
  imcData: CholesterolMonitoring[] | undefined
}

const CholesterolChart: React.FC<CholesterolChartProps> = ({imcData}) => {
    console.log(imcData)
    
  function formatDate(dateString) {
    const date = new Date(dateString)
    const day = String(date.getUTCDate()).padStart(2, '0')
    const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are 0-based in JavaScript
    const year = date.getUTCFullYear()

    return `${day}/${month}/${year}`
  }
  const trace = {
    x: imcData?.map((entry) => formatDate(entry.date)),
    y: imcData?.map((entry) => entry.value ),
    yaxis: 'y',
    type: 'scatter',
    mode: 'markers',

    marker: {color: '#203879', size: 12},
    showlegend: false,
  }

  const data = [trace]

  const layout = {
    title: 'Cholestérol',
    barmode: 'stack',
    bargap: 0.2,
    bargroupgap: 0.1,

    xaxis: {
      title: 'Dates',
      autorange: true,
      type: 'category',
      //   tickangle: -45,
    },
    yaxis: {
      titlefont: {color: '#203879'},
      tickfont: {color: '#203879'},
      title: 'LDL (g/L)',
      showgrid: false,
      zeroline: false,
    },
    legend: {
      x: 0,
      y: -0.4,
      orientation: 'h',
    },
  }

  return (
    <>
      {imcData ? (
        <div className='contentBox mt-2'>
          <div className='contentFilter row col-md-12'>
            <Plot
              style={{width: '100%', height: '335px'}}
              data={data}
              layout={layout}
              config={{responsive: true, displaylogo: false}}
            />
          </div>
        </div>
      ) : (
        <p>Pas donnés...</p>
      )}
    </>
  )
}

export default CholesterolChart
