import React from 'react'

const ActiviteIcon = ({fillColor, size}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 59 59'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='29.5' cy='29.5' r='28.5' fill={fillColor} stroke='white' stroke-width='2' />
      <path
        d='M28.3415 36.8449L13.8507 24.5449L13 25.5477L27.4929 37.8487L27.495 37.8505C29.1421 39.2366 31.2332 39.9995 33.387 40H42.675V38.6851H33.387C31.545 38.6851 29.7506 38.0315 28.3415 36.8449Z'
        fill='white'
      />
      <path
        d='M33.387 37.3707C33.387 37.3707 37.0879 37.3707 40.3746 37.3707C43.6612 37.3707 43.1684 34.0838 41.032 33.5911C39.5905 33.2584 37.7398 32.4937 37.7398 32.4937C37.0093 32.2501 36.4112 31.7161 36.0879 31.0184C36.0879 31.0184 36.0079 30.8488 35.8737 30.5634L33.1907 31.3746C32.8975 31.4633 32.5878 31.2978 32.4991 31.0047C32.4109 30.7115 32.5761 30.4017 32.8693 30.3135L35.3974 29.5487C35.18 29.0855 34.928 28.5473 34.6624 27.9793L32.1549 28.7381C31.8617 28.8268 31.552 28.66 31.4633 28.3677C31.3743 28.0744 31.541 27.7647 31.8334 27.6761L34.1874 26.9642C33.9369 26.4265 33.6878 25.8911 33.4571 25.3933L31.119 26.101C30.8258 26.1893 30.516 26.0238 30.4272 25.7306C30.3392 25.4374 30.5043 25.1277 30.7976 25.0394L32.9877 24.3767C32.6738 23.6933 32.446 23.1869 32.3854 23.0297C32.1218 22.3445 31.543 20.4437 30.2222 21.5002C28.0505 23.2388 24.0887 22.7291 23.0659 20.8288C22.2992 19.4045 22.7375 17.871 23.0659 16.6659C23.3288 15.7027 21.9701 14.2008 20.9845 15.5156C20.2871 16.4449 14.4111 23.4028 14.4111 23.4028L29.1883 35.8389C30.3639 36.8284 31.8507 37.3707 33.387 37.3707Z'
        fill='white'
      />
    </svg>
  )
}

export default ActiviteIcon
