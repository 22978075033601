import React from 'react'

const TabagismeIcon = ({fillColor, size}) => {
  return (
    <svg width={size} height={size} viewBox='0 0 59 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='29.5' cy='29.5' r='28.5' fill={fillColor} stroke='white' stroke-width='2' />
      <path
        d='M43.7911 27.0795H41.1917H19.5308H10.7427C10.3327 27.0795 10 27.4079 10 27.8125V33.267C10 33.6716 10.3327 34 10.7427 34H19.5308H41.1917H43.7911C44.201 34 44.5337 33.6716 44.5337 33.267V27.8125C44.5337 27.4079 44.201 27.0795 43.7911 27.0795ZM20.2735 28.5455H40.4491V32.534H20.2735V28.5455ZM43.0484 32.534H41.9344V28.5455H43.0484V32.534ZM41.5076 19.3526C40.9526 17.9169 41.3789 16.2027 42.5454 15.1843C42.8528 14.915 43.3227 14.9448 43.5935 15.2473C43.8653 15.5503 43.8366 16.0136 43.5296 16.2818C42.8281 16.8946 42.5612 17.9663 42.8954 18.8303C43.0682 19.2769 43.387 19.6903 43.7257 20.1277C44.102 20.6149 44.4911 21.1187 44.7426 21.7398C45.3997 23.3675 44.758 25.4043 43.2811 26.3758C43.1544 26.4594 43.0113 26.4989 42.8697 26.4989C42.63 26.4989 42.3944 26.3846 42.2513 26.1725C42.0235 25.8358 42.1161 25.3809 42.4572 25.1561C43.3568 24.5638 43.7638 23.2747 43.3628 22.2827C43.1875 21.8478 42.8751 21.4441 42.5449 21.0166C42.1567 20.5157 41.7567 19.9972 41.5076 19.3526Z'
        fill='white'
      />
    </svg>
  )
}

export default TabagismeIcon
