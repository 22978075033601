import React from 'react'

const PoidIcon = ({fillColor, size}) => {
  return (
    <svg width={size} height={size} viewBox='0 0 59 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='29.5' cy='29.5' r='28.5' fill={fillColor} stroke='white' stroke-width='2' />
      <path
        d='M30.0003 20.3333L28.5648 24.6667M36.5 20.3333C38.519 20.3333 39.5286 20.3333 40.325 20.6632C41.3867 21.103 42.2304 21.9466 42.6701 23.0084C43 23.8047 43 24.8143 43 26.8333V35.0667C43 37.4936 43 38.707 42.5277 39.634C42.1122 40.4494 41.4494 41.1122 40.634 41.5277C39.707 42 38.4936 42 36.0667 42H23.9333C21.5064 42 20.293 42 19.366 41.5277C18.5507 41.1122 17.8878 40.4494 17.4723 39.634C17 38.707 17 37.4936 17 35.0667V26.8333C17 24.8143 17 23.8047 17.3299 23.0084C17.7697 21.9466 18.6133 21.103 19.675 20.6632C20.4714 20.3333 21.4809 20.3333 23.5 20.3333M27.1111 36.2222H32.8889M27.8933 16H32.1072C33.6685 16 34.4492 16 35.027 16.2678C35.7974 16.6249 36.3717 17.3029 36.5974 18.1216C36.7665 18.7356 36.6382 19.5056 36.3816 21.0457C36.1912 22.1877 36.096 22.7586 35.8426 23.2046C35.5049 23.7992 34.9672 24.2547 34.3251 24.4901C33.8437 24.6667 33.2647 24.6667 32.1072 24.6667H27.8933C26.7356 24.6667 26.1568 24.6667 25.6753 24.4901C25.0332 24.2547 24.4955 23.7992 24.1577 23.2046C23.9044 22.7586 23.8093 22.1877 23.6189 21.0457C23.3623 19.5056 23.2339 18.7356 23.4031 18.1216C23.6286 17.3029 24.203 16.6249 24.9735 16.2678C25.5513 16 26.332 16 27.8933 16Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default PoidIcon
