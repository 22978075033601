import React from 'react'

const CholesterolIcon = ({fillColor, size}) => {
  return (
    <svg width={size} height={size} viewBox='0 0 59 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='29.5' cy='29.5' r='28.5' fill={fillColor} stroke='white' stroke-width='2' />
      <path
        d='M42.3331 20.0667C41.5938 19.3124 40.7124 18.7119 39.7398 18.3C38.7672 17.8881 37.7227 17.6728 36.6665 17.6667C34.0665 17.6667 31.3331 19.7334 29.9998 22C28.6665 19.7334 25.9331 17.6667 23.3331 17.6667C22.2769 17.6728 21.2324 17.8881 20.2598 18.3C19.2872 18.7119 18.4058 19.3124 17.6665 20.0667C16.9212 20.8093 16.3311 21.6928 15.9306 22.6656C15.53 23.6385 15.3269 24.6813 15.3331 25.7334C15.3606 27.1304 15.7761 28.4922 16.5331 29.6667H23.2665L25.3998 26C25.4675 25.9037 25.5573 25.8251 25.6617 25.7709C25.7662 25.7166 25.8821 25.6883 25.9998 25.6883C26.1175 25.6883 26.2334 25.7166 26.3379 25.7709C26.4423 25.8251 26.5322 25.9037 26.5998 26L29.9998 31.7334L31.2665 29.6667H36.5998C36.9292 29.6607 37.2502 29.771 37.5063 29.9783C37.7624 30.1856 37.9371 30.4766 37.9998 30.8C38.0288 30.9911 38.0159 31.1862 37.962 31.3719C37.9082 31.5575 37.8146 31.7292 37.6877 31.875C37.5609 32.0209 37.4039 32.1374 37.2275 32.2165C37.0512 32.2957 36.8598 32.3355 36.6665 32.3334H32.7331L30.5998 36C30.5322 36.0963 30.4423 36.1749 30.3379 36.2291C30.2334 36.2834 30.1175 36.3117 29.9998 36.3117C29.8821 36.3117 29.7662 36.2834 29.6617 36.2291C29.5573 36.1749 29.4675 36.0963 29.3998 36L25.9998 30.2667L24.7331 32.3334H18.5331C18.5331 32.3687 18.5472 32.4026 18.5722 32.4276C18.5972 32.4526 18.6311 32.4667 18.6665 32.4667C19.9998 34 27.5331 42 29.5331 44.1334C29.6578 44.2555 29.8253 44.3239 29.9998 44.3239C30.1743 44.3239 30.3419 44.2555 30.4665 44.1334C32.4665 42 39.9998 34 41.3331 32.4667C42.6665 30.9334 44.6665 28.4667 44.6665 25.7334C44.6727 24.6813 44.4697 23.6385 44.0691 22.6656C43.6685 21.6928 43.0784 20.8093 42.3331 20.0667Z'
        fill='white'
      />
    </svg>
  )
}

export default CholesterolIcon
