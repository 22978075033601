import React from 'react'

const PressionIcon = ({fillColor, size}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 59 59'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='29.5'
        cy='29.5'
        r='28.5'
        fill={fillColor}
        fill-opacity='0.96'
        stroke='white'
        stroke-width='2'
      />
      <path
        d='M17 18H26.6C27.2326 18.006 27.8371 18.2615 28.2823 18.711C28.7275 19.1604 28.9772 19.7674 28.9771 20.4V27.6C28.9772 28.2326 28.7275 28.8396 28.2823 29.289C27.8371 29.7385 27.2326 29.994 26.6 30H17V18Z'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
      />
      <path
        d='M36.6343 27.8171C39.0454 27.8171 41 25.8625 41 23.4514C41 21.0403 39.0454 19.0857 36.6343 19.0857C34.2232 19.0857 32.2686 21.0403 32.2686 23.4514C32.2686 25.8625 34.2232 27.8171 36.6343 27.8171Z'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
      />
      <path
        d='M24.5889 28.9143V30C24.5889 30.5753 24.7025 31.1449 24.9234 31.676C25.1442 32.2072 25.4678 32.6895 25.8756 33.0952C26.2834 33.5009 26.7674 33.822 27.2997 34.04C27.832 34.2581 28.4022 34.3688 28.9774 34.3658H32.3032C33.456 34.3597 34.5594 33.8967 35.3714 33.0783C36.1833 32.2599 36.6376 31.1529 36.6346 30V23.4515'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
      />
      <path
        d='M37.7317 37.6343H34.4631C33.2576 37.6343 32.2803 38.6116 32.2803 39.8171C32.2803 41.0227 33.2576 42 34.4631 42H37.7317C38.9373 42 39.9146 41.0227 39.9146 39.8171C39.9146 38.6116 38.9373 37.6343 37.7317 37.6343Z'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
      />
      <path d='M42.0969 39.8171H39.9141' stroke='white' stroke-width='2' stroke-miterlimit='10' />
      <path
        d='M19.1826 28.9143V31.1429C19.1826 33.4586 20.1025 35.6795 21.74 37.3169C23.3775 38.9544 25.5983 39.8743 27.914 39.8743H32.2798'
        stroke='white'
        stroke-width='2'
        stroke-miterlimit='10'
      />
      <path d='M21.3662 18V26.7314' stroke='white' stroke-width='2' stroke-miterlimit='10' />
    </svg>
  )
}

export default PressionIcon
