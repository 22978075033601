import React from 'react'

const RenaleFonctionIcon = ({fillColor, size}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 60 59'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M59 29.5C59 45.2243 46.0322 58 30 58C13.9678 58 1 45.2243 1 29.5C1 13.7757 13.9678 1 30 1C46.0322 1 59 13.7757 59 29.5Z'
        fill={fillColor}
        stroke='white'
        stroke-width='2'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M26.6164 19.0907C25.7963 18.1728 24.7376 17.6396 23.7061 17.77C20.6219 18.1602 17.4514 21.0716 16.1221 24.7046C14.8195 28.2646 15.3976 32.201 19.3621 34.6845C20.0842 35.1368 20.6263 35.2829 21.0969 35.244C21.5702 35.2048 22.157 34.9629 22.929 34.2673C23.5588 33.6999 24.013 32.5912 24.2872 31.1747C24.5527 29.8022 24.6178 28.3186 24.6178 27.1852C24.6178 25.703 25.4713 24.5943 26.1862 23.7506C26.296 23.6209 26.4009 23.4994 26.5013 23.3832C26.7513 23.0941 26.9729 22.8376 27.1721 22.5677C27.4419 22.2023 27.6029 21.897 27.6655 21.6114C27.7178 21.3733 27.6874 20.9912 27.5014 20.5083C27.3202 20.0383 27.0157 19.5376 26.6164 19.0907ZM29.2658 19.9162C29.5201 20.5762 29.6477 21.301 29.5024 21.9627C29.3676 22.5768 29.0512 23.1067 28.7161 23.5607C28.4736 23.8892 28.1849 24.2232 27.9193 24.5305C27.8282 24.6359 27.7398 24.7382 27.6569 24.836C26.9561 25.6631 26.4928 26.3539 26.4928 27.1852C26.4928 28.3652 26.4263 29.9663 26.1322 31.4857C25.8468 32.961 25.3133 34.5515 24.2313 35.5264C23.2914 36.3731 22.3237 36.8993 21.2625 36.9871C20.1987 37.0752 19.2266 36.7072 18.3172 36.1375C13.4592 33.0943 12.8521 28.2265 14.3477 24.1391C15.8166 20.1246 19.4276 16.5453 23.4542 16.0359C25.3725 15.7932 27.0132 16.8023 28.0564 17.9699C28.5887 18.5657 29.0066 19.2435 29.2658 19.9162Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M25.7298 29.2675C25.7302 29.2675 25.7306 29.2676 25.9845 28.4254C26.2386 27.5831 26.239 27.5832 26.2394 27.5833L26.2422 27.584L26.2468 27.5853L26.2592 27.5887C26.2688 27.5913 26.2811 27.5948 26.2959 27.5991C26.3255 27.6078 26.3653 27.6198 26.4137 27.6356C26.5104 27.667 26.6428 27.7135 26.7992 27.7774C27.1095 27.9042 27.5276 28.1049 27.951 28.4016C28.8036 28.9987 29.7294 30.0304 29.7294 31.6209V43.7876C29.7294 43.7877 29.7294 43.7881 28.7919 43.7881C27.8544 43.7881 27.8544 43.7881 27.8544 43.788V31.6209C27.8544 30.7533 27.3765 30.1872 26.8255 29.8012C26.547 29.6061 26.2633 29.4688 26.0473 29.3805C25.9404 29.3369 25.8536 29.3066 25.7967 29.2881C25.7684 29.2789 25.7478 29.2727 25.7363 29.2694L25.7261 29.2664C25.7253 29.2662 25.7255 29.2663 25.7261 29.2664L25.728 29.2669L25.7298 29.2675Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M34.5497 19.0907C35.3697 18.1728 36.4284 17.6396 37.46 17.77C40.5441 18.1602 43.7147 21.0716 45.044 24.7046C46.3465 28.2646 45.7685 32.201 41.804 34.6845C41.082 35.1368 40.5397 35.2829 40.0691 35.244C39.5959 35.2048 39.0091 34.9629 38.237 34.2673C37.6072 33.6999 37.1531 32.5912 36.8789 31.1747C36.6134 29.8022 36.5482 28.3186 36.5482 27.1852C36.5482 25.703 35.6947 24.5943 34.9799 23.7506C34.87 23.6209 34.7651 23.4994 34.6647 23.3832C34.4148 23.0941 34.1931 22.8376 33.9939 22.5677C33.7242 22.2023 33.5632 21.897 33.5005 21.6114C33.4482 21.3733 33.4787 20.9912 33.6647 20.5083C33.8458 20.0383 34.1503 19.5376 34.5497 19.0907ZM31.9003 19.9162C31.646 20.5762 31.5184 21.301 31.6637 21.9627C31.7985 22.5768 32.1149 23.1067 32.45 23.5607C32.6925 23.8892 32.9811 24.2232 33.2467 24.5305C33.3379 24.6359 33.4263 24.7382 33.5091 24.836C34.2099 25.6631 34.6732 26.3539 34.6732 27.1852C34.6732 28.3652 34.7398 29.9663 35.0338 31.4857C35.3193 32.961 35.8527 34.5515 36.9348 35.5264C37.8746 36.3731 38.8424 36.8993 39.9035 36.9871C40.9673 37.0752 41.9394 36.7072 42.8489 36.1375C47.7068 33.0943 48.314 28.2265 46.8184 24.1391C45.3495 20.1246 41.7384 16.5453 37.7119 16.0359C35.7935 15.7932 34.1528 16.8023 33.1097 17.9699C32.5775 18.5657 32.1594 19.2435 31.9003 19.9162Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M35.4371 29.2675C35.4367 29.2675 35.4363 29.2676 35.1823 28.4254C34.9284 27.5831 34.9279 27.5832 34.9275 27.5833L34.9247 27.584L34.9201 27.5853L34.9077 27.5887C34.8981 27.5913 34.8858 27.5948 34.871 27.5991C34.8414 27.6078 34.8016 27.6198 34.7533 27.6356C34.6565 27.667 34.5241 27.7135 34.3677 27.7774C34.0574 27.9042 33.6393 28.1049 33.2159 28.4016C32.3633 28.9987 31.4375 30.0304 31.4375 31.6209V43.7876C31.4375 43.7877 31.4375 43.7881 32.375 43.7881C33.3125 43.7881 33.3125 43.7881 33.3125 43.788V31.6209C33.3125 30.7533 33.7903 30.1872 34.3414 29.8012C34.6198 29.6061 34.9036 29.4688 35.1197 29.3805C35.2265 29.3369 35.3133 29.3066 35.3702 29.2881C35.3985 29.2789 35.4191 29.2727 35.4306 29.2694L35.4408 29.2664C35.4414 29.2663 35.4416 29.2662 35.4408 29.2664L35.4389 29.2669L35.4371 29.2675Z'
        fill='white'
      />
    </svg>
  )
}

export default RenaleFonctionIcon
