import React from 'react'

const DiabetesIcon = ({fillColor, size}) => {
  return (
    <svg width={size} height={size} viewBox='0 0 60 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M59 29.5C59 45.2243 46.0322 58 30 58C13.9678 58 1 45.2243 1 29.5C1 13.7757 13.9678 1 30 1C46.0322 1 59 13.7757 59 29.5Z'
        fill={fillColor}
        stroke='white'
        stroke-width='2'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M46.4777 26.5272C46.4772 25.8741 45.9351 25.3451 45.2668 25.3457L29.7516 25.3581C27.7021 25.3598 27.1947 22.5737 29.119 21.8843L31.2137 21.1337C31.9792 20.8594 32.1987 19.9039 31.6268 19.336C31.319 19.0303 30.8569 18.9282 30.4445 19.0748L19.4624 22.9767C15.8694 24.2532 13.4786 27.5915 13.4818 31.3277C13.4859 36.231 17.5565 40.2026 22.5738 40.1986L32.7154 40.1905C33.2215 40.1901 33.6317 39.7887 33.6312 39.2939L33.6307 38.7207C33.6304 38.2259 33.2196 37.8251 32.7134 37.8255L29.2888 37.8282L29.2872 36.0366L35.8018 36.0314C36.3081 36.031 36.7181 35.6296 36.7177 35.1348L36.7172 34.5616C36.7168 34.0668 36.306 33.666 35.7998 33.6665L31.375 33.67L31.3735 31.8784L36.4911 31.8743C36.9974 31.8738 37.4074 31.4724 37.4071 30.9777L37.4066 30.4044C37.4061 29.9096 36.9954 29.5089 36.4891 29.5093L31.3715 29.5134L31.3701 27.7217L45.2687 27.7106C45.937 27.7101 46.4783 27.1803 46.4777 26.5272ZM39.0826 29.5072L45.2703 29.5023C46.951 29.5009 48.3125 28.1683 48.3111 26.5257C48.3097 24.8832 46.9461 23.5527 45.2653 23.554L29.7501 23.5664L31.8448 22.8159C33.8617 22.0932 34.4403 19.5754 32.9334 18.0791C32.1223 17.2738 30.9049 17.0049 29.8181 17.391L18.836 21.2928C14.5179 22.827 11.6447 26.839 11.6484 31.3292C11.6534 37.2219 16.5455 41.9951 22.5753 41.9903L32.7168 41.9821C34.2356 41.981 35.4658 40.7767 35.4645 39.2925L35.4641 38.7191C35.4638 38.4051 35.4084 38.1036 35.3069 37.8235L35.8032 37.823C37.3221 37.8219 38.5522 36.6176 38.551 35.1334L38.5505 34.56C38.5501 34.0366 38.3965 33.5482 38.1312 33.1352C38.805 32.6449 39.2411 31.8603 39.2404 30.9762L39.2399 30.4029C39.2396 30.0889 39.1842 29.7873 39.0826 29.5072Z'
        fill='white'
      />
      <path
        d='M45.5684 35.3811C45.5696 36.8144 44.3605 37.9415 42.8205 37.9428C41.2805 37.944 40.0696 36.8188 40.0684 35.3855C40.0671 33.9521 42.8144 30.7761 42.8144 30.7761C42.8144 30.7761 45.5672 34.0501 45.5684 35.3811Z'
        fill='white'
      />
      <path
        d='M44.6452 27.6392C44.6458 28.3814 43.8255 28.9836 42.813 28.9844C41.8005 28.9852 40.9792 28.3842 40.9785 27.6421C40.9779 26.9 41.7982 26.2977 42.8108 26.2969C43.8233 26.2961 44.6445 26.8971 44.6452 27.6392Z'
        fill='white'
      />
    </svg>
  )
}

export default DiabetesIcon
